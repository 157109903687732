<template>
  <div class="px-20 py-16 flex flex-col items-left">
    <div class="headline">Oops, this page was not found</div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
