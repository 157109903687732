<template>
  <div
    id="header"
    class="
      w-full
      bg-white
      text-gray-800
      sticky
      top-0
      flex
      justify-center
      border-gray-200 border-b
    "
    style="z-index: 1000"
  >
    <div
      class="
        flex flex-row
        justify-between
        items-center
        py-2
        h-16
        max-h-16
        w-5/6
      "
    >
      <div class="">
        <a href="/">
          <div class="flex items-center">
            <img
              src="../assets/logo.png"
              alt="Webstake logo"
              class="h-6 mr-2"
            />
            <img
              src="../assets/webstake-no-logo.png"
              alt="Webstake GmbH"
              class="webstake-text-logo h-6"
            />
          </div>
        </a>
      </div>
      <div class="flex flex-row flex-nowrap justify-end">
        <nav id="hamnav">
          <label for="hamburger"><i class="fa-solid fa-bars"></i></label>
          <input type="checkbox" id="hamburger" />

          <div id="hamitems">
            <router-link class="menu-link" @click="linkClicked" to="/">
              <i class="fa-solid fa-caret-right invisible caret"></i>
              Home
            </router-link>
            <router-link class="menu-link" @click="linkClicked" to="/services">
              <i class="fa-solid fa-caret-right invisible caret"></i>
              Services
            </router-link>
            <!-- <router-link class="menu-link" @click="linkClicked" to="/concept">
              <i class="fa-solid fa-caret-right invisible caret"></i>
              {{ $t("nav.concept") }}
            </router-link> -->
            <router-link class="menu-link" @click="linkClicked" to="/about-us">
              <i class="fa-solid fa-caret-right invisible caret"></i>
              {{ $t("nav.aboutUs") }}
            </router-link>
            <router-link
              class="menu-link"
              @click="linkClicked"
              to="/open-positions"
            >
              <i class="fa-solid fa-caret-right invisible caret"></i>
              {{ $t("nav.joinUs") }}
            </router-link>
            <router-link class="menu-link" @click="linkClicked" to="/contact">
              <i class="fa-solid fa-caret-right invisible caret"></i>
              {{ $t("nav.contact") }}
            </router-link>
          </div>
        </nav>

        <a
          class="social-media-link text-webstake-blue text-xl ml-8"
          href="https://www.linkedin.com/company/webstake-gmbh"
          target="_blank"
          title="Folge uns auf LinkedIn"
        >
          <i class="fa-brands fa-linkedin"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {},
  methods: {
    linkClicked: function () {
      document.getElementById("hamburger").checked = false;
    },
    handleScroll() {
      document
        .getElementById("header")
        .classList.toggle("floating", window.scrollY > 0);
    },
  },
  mounted() {
    // Note: do not add parentheses () for this.handleScroll
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
#header {
  transition: 1s;
}
#header.floating {
  @apply shadow-xl;
}

#hamitems .caret {
  @apply hidden;
}

.logo-image {
  height: 50px;
}

#hamnav {
  width: 100%;
}

#hamitems {
  transition: visibility 1s;
  -webkit-transition: visbility 1s;
}

#hamnav label,
#hamburger {
  display: none;
}

@media screen and (max-width: 1000px) {
  #hamitems {
    @apply invisible flex flex-col justify-around justify-items-center content-between items-start;
    -webkit-box-shadow: 4px 10px 10px -7px #000000;
    box-shadow: 4px 10px 10px -7px #000000;
    position: absolute;
    top: 4rem;
    right: 0;
    width: 100vw;
    background-color: white;
  }

  #hamitems .menu-link {
    @apply px-8 py-4 text-left;
    font-size: 16px;
  }
  #hamitems .caret {
    @apply invisible;
    display: inline-block;
  }

  #hamitems .router-link-active {
    @apply border-none;
    color: var(--webstake-blue);
  }
  #hamnav input:checked ~ #hamitems .router-link-active .caret {
    @apply visible;
  }

  #hamnav label {
    @apply flex items-center justify-center border rounded-xl;
    font-size: 24px;
    width: 100px;
    color: white;
    background-color: var(--webstake-blue);
    display: inline-block;
    cursor: pointer;
    transition: font-size 0.3s;
  }
  #hamnav label:hover {
    font-size: 22px;
  }

  #hamnav input:checked ~ #hamitems {
    visibility: visible;
  }

  .social-media-link,
  .webstake-text-logo {
    display: none;
  }
}
</style>
