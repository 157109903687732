<template>
  <div id="contact" class="section bg-gray-100 text-jet-black">
    <div class="section-content">
      <div class="headline">Kontakt</div>

      <div class="ctaBox-vertical" style="align-items: flex-start">
        <div class="text-2xl text-left">
          Wenn Du Fragen hast, Dich bewerben möchtest oder einfach mehr über
          unser Konzept und unser Team erfahren möchtest, sprich uns gerne an.
        </div>

        <div class="flex flex-row items-center h-6">
          <i class="fa-solid fa-at mr-4" title="E-Mail Adresse"></i>
          <a href="kontakt@webstake.de" class="link">kontakt@webstake.de</a>
        </div>
        <div class="flex flex-row items-center h-6">
          <i class="fa-solid fa-phone mr-4" title="Telefonnummer"></i>
          <a class="text-left link" href="tel:+494052470009"
            >+49 (0)40 5247 0009</a
          >
        </div>
        <div class="flex flex-row items-center h-6">
          <i
            class="fa-brands fa-linkedin mr-4"
            title="Linkedin Firmenprofil"
          ></i>
          <a
            class="text-left link"
            href="https://www.linkedin.com/company/webstake-informatik-gmbh/about/"
            >Webstake GmbH</a
          >
        </div>
      </div>

      <!-- <div class="headline-3 mt-12 mb-4">
        Dein Ansprechpartner für Recruiting
      </div>

      <div class="flex flex-row flex-wrap gap-4 justify-start mt-4">
        <div class="person">
          <img
            class="head-shot"
            src="../assets/pic-steffen.jpeg"
            alt="Picture Steffen"
          />
        </div>
        <div class="flex flex-col items-start">
          <div class="person-name">Türoff, Steffen</div>
          <div class="person-detail">Recruiting, Finanzen, IT</div>
          <div class="person-detail">
            <a class="link" href="mailto:steffen.tueroff@webstake.de"
              >steffen.tueroff@webstake.de</a
            >
          </div>
          <div>
            <a href="https://www.linkedin.com/in/steffentueroff/" class="link">
              <i
                class="fa-brands fa-linkedin"
                title="Linkedin Profil Steffen"
              ></i>
              Vernetz Dich mit mir auf LinkedIn
            </a>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  props: {},
};
</script>

<style scoped>
.head-shot {
  @apply w-28 rounded-md border border-black;
}

.person {
}

.person-name {
  @apply font-bold;
  color: var(--webstake-blue);
}

.link:hover {
  @apply text-3xl;
  transition: 0.5s;
}
</style>
