<template>
  <div
    id="career"
    class="section bg-gray-100 text-jet-black items-start justify-start"
  >
    <div class="section-content">
      <h1 class="headline">Unser Konzept</h1>

      <h2 class="headline-2">Warum Webstake?</h2>
      <p class="paragraph">
        Das Webstake-Konzept ist aus der Erfahrung von über 15 Jahren
        IT-Projektarbeit als Freiberufler, ANÜ-ler und in Festanstellung
        entstanden. Mit unserem Modell wollen wir der beste Arbeitgeber für alle
        sein, die der Enge einer Festanstellung entwachsen sind, aber den Sprung
        in die totale Ungebundenheit der Freiberuflichkeit aus verschiedenen
        Gründen meiden. Webstake positioniert sich dort, wo die Stärken aus den
        Modellen Festanstellung und Selbständigkeit zusammenkommen.
      </p>

      <h2 class="headline-2">Gute Gründe</h2>
      <h3 class="headline-3 text-webstake-blue">
        Kümmer Du Dich um das Wesentliche, wir übernehmen den Rest
      </h3>
      <p class="paragraph">
        Hinter jedem Profisportler steht heute ein Management-Team, das ihm den
        Rücken freihält, damit er sich auf das Wesentliche konzentrieren kann,
        nämlich seine Leistung. Vertragsverhandlungen werden von Beratern
        übernommen, um dem Marktwert entsprechende Konditionen zu auszuhandeln.
        Wir sehen uns als Firma für unsere Angestellten IT-Experten in
        ebendieser Rolle.
      </p>
      <h3 class="headline-3 text-webstake-blue">
        Top Gehälter, fair und transparent kalkuliert
      </h3>
      <p class="paragraph">
        Als unser Mitarbeiter partizipierst Du fair und ganz transparent, ohne
        Wenn und Aber, an Deiner Wertschöpfung. Jeder Euro, den wir für Deine
        Leistung beim Kunden berechnen, wirkt sich direkt auf Deine Vergütung
        aus. Dadurch gibt es bei uns auch keine Gehaltsbänder, Gehaltsgrenzen,
        Quersubventionierung oder Verteilungskämpfe. Als Unternehmen profitieren
        wir nur, wenn wir Deinen Marktwert steigern und Du selbst ebenfalls
        profitierst.
      </p>
      <h3 class="headline-3 text-webstake-blue">
        Kontinuierliche Weiterbildung
      </h3>
      <p class="paragraph">
        Lebenslanges Lernen ist in der IT unumgänglich, wenn man nicht den
        Anschluss verlieren möchte. Das fordern und fördern wir in unserer
        Webstake-Community, untereinander und mit Dritten. Um Dich zu dem
        stärksten Consultant zu machen, der Du sein kannst.
      </p>
      <h3 class="headline-3 text-webstake-blue">IT ist Teamwork</h3>
      <p class="paragraph">
        Die Erwartungen an einen beauftragten Externen sind bei Kunden oft groß.
        Unser breit aufgestellter Expertenpool ist nur ein paar Tastenschläge
        entfernt, um Dich bei fachlichen Fragen jederzeit zu unterstützen. Wir
        gewinnen letztendlich immer auch als Team!
      </p>
      <h3 class="headline-3 text-webstake-blue">Community</h3>
      <p class="paragraph">
        Auch IT Fachkräfte sind Menschen und brauchen Gemeinschaft! Wir feiern
        zusammen unsere Erfolge und teilen unsere Geschichten. Wir wollen den
        Weg gemeinsam bestreiten und uns gegenseitig stark machen.
      </p>

      <h2 class="headline-2">Wir suchen Dich</h2>
      <p class="paragraph" style="padding-bottom: 0">
        Um Teil unseres Teams zu werden, solltest Du Folgendes mitbringen:
      </p>
      <ul class="pb-8">
        <li class="text-left pl-6 mt-4">
          <i class="fa-solid fa-caret-right text-webstake-blue pr-2"></i>
          Webstake steht für hohe Qualität. Wir suchen erfahrene
          Softwareentwickler oder IT-Experten, die Ihr Fachgebiet oder Ihren
          Tech-Stack nachweislich beherrschen.
        </li>
        <li class="text-left pl-6 mt-4">
          <i class="fa-solid fa-caret-right text-webstake-blue pr-2"></i>
          Im Projektgeschäft sind die Anforderungen an den Berater nicht nur
          fachlich hoch, auch auf Soft Skills kommt es an. Wir achten bei der
          Auswahl unseres Personals darauf, dass der Kandidat unternehmerische
          Fähigkeiten wie Selbständigkeit, Verlässlichkeit, Motivation und
          Zuverlässigkeit mitbringt.
        </li>
        <li class="text-left pl-6 mt-4">
          <i class="fa-solid fa-caret-right text-webstake-blue pr-2"></i>
          Wir suchen Menschen mit der Motivation, beim Kunden gute Leistungen zu
          bringen und sich weiterzuentwickeln.
        </li>
      </ul>

      <h2 class="headline-2">Do you have what it takes?</h2>
      <p class="paragraph">
        Dann möchten wir Dich unbedingt kennenlernen! Wir suchen laufend nach
        starken Kandidaten, die sich mit unseren Werten identifizieren.
        Kontaktieren kannst Du uns über alle gängigen Kanäle außer Fax :) -
        sprich uns ganz unverbindlich an, dann schauen wir, ob wir
        zusammenpassen.
      </p>
      <div>
        <router-link to="/contact" class="btn"
          >Jetzt Gespräch vereinbaren</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Career",
  props: {},
};
</script>

<style scoped>
</style>
