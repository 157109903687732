import { createWebHistory, createRouter } from "vue-router";
import Frontpage from "@/views/Frontpage.vue";
import Services from "@/views/Services.vue";
import Concept from "@/views/Concept.vue";
import OpenPositions from "@/views/OpenPositions.vue";
import AboutUs from "@/views/AboutUs.vue";
import Contact from "@/views/Contact.vue";

import Imprint from "@/views/Imprint.vue";
import Privacy from "@/views/Privacy.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [
    {
        path: "/",
        component: Frontpage,
    },
    {
        path: "/services",
        component: Services,
    },
    {
        path: "/concept",
        component: Concept,
    },
    {
        path: "/open-positions",
        component: OpenPositions,
    },
    {
        path: "/about-us",
        component: AboutUs,
    },
    {
        path: "/contact",
        component: Contact,
    },
    {
        path: "/imprint",
        name: "Imprint",
        component: Imprint,
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: Privacy,
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
        // if (to.hash) {
        //     return { top: 0, el: "#top" }
        // }
    },
});

export default router;