<template>
  <div class="content-block items-start bg-gray-100 text-jet-black">
    <div class="headline-2 text-webstake-blue">Datenschutzerklärung</div>
    <div class="paragraph leading-normal">
      Die Nutzung unserer Seite ist ohne eine Angabe von personenbezogenen Daten
      möglich. Für die Nutzung einzelner Services unserer Seite können sich
      hierfür abweichende Regelungen ergeben, die in diesem Falle nachstehend
      gesondert erläutert werden. Ihre personenbezogenen Daten (z.B. Name,
      Anschrift, E-Mail, Telefonnummer, u.ä.) werden von uns nur gemäß den
      Bestimmungen des deutschen Datenschutzrechts verarbeitet. Daten sind dann
      personenbezogen, wenn sie eindeutig einer bestimmten natürlichen Person
      zugeordnet werden können. Die rechtlichen Grundlagen des Datenschutzes
      finden Sie im Bundesdatenschutzgesetz (BDSG) und dem Telemediengesetz
      (TMG). Nachstehende Regelungen informieren Sie insoweit über die Art, den
      Umfang und Zweck der Erhebung, die Nutzung und die Verarbeitung von
      personenbezogenen Daten durch den Anbieter
    </div>
    <div class="paragraph font-bold">
      Webstake GmbH<br />
      Telefon: +49 (0)40 - 5247 0009<br />
      e-mail: kontakt@webstake.de
    </div>
    <div class="paragraph">
      Wir weisen darauf hin, dass die internetbasierte Datenübertragung
      Sicherheitslücken aufweist, ein lückenloser Schutz vor Zugriffen durch
      Dritte somit unmöglich ist.
    </div>

    <div class="headline-2 text-webstake-blue">Kontaktmöglichkeit</div>
    <div class="paragraph">
      Wir bieten Ihnen auf unserer Seite die Möglichkeit, mit uns per E-Mail in
      Verbindung zu treten. In diesem Fall werden die vom Nutzer gemachten
      Angaben zum Zwecke der Bearbeitung seiner Kontaktaufnahme gespeichert.
      Eine Weitergabe an Dritte erfolgt nicht. Ein Abgleich der so erhobenen
      Daten mit Daten, die möglicherweise durch andere Komponenten unserer Seite
      erhoben werden, erfolgt ebenfalls nicht.
    </div>
  </div>
</template>

<script>
export default {
  name: "Privacy",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
