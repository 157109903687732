<template>
  <div class="w-100" style="background-color: #f8f8f8">
    <div
      id="frontpage"
      class="
        justify-center
        items-center
        justify-items-center
        content-center
        flex flex-col
        w-full
      "
    >
      <div class="sectionColumn">
        <div
          class="titleLine text-center font-bold text-webstake-blue-dark"
          style="padding-top: max(6vw, 100px)"
        >
          <span class="text-webstake-blue">
            Software Engineering Excellence
          </span>
          with Integrity and Fairness
          <!-- {{ $t("titleLine1") }}
          <span class="text-webstake-blue">{{ $t("titleLine2") }}</span> -->
        </div>
        <div
          class="text-center normal-case tagLine mt-12"
          style="padding-left: 10vw; padding-right: 10vw"
        >
          <!-- {{ $t("frontpage.tagline") }} -->
          Webstake ist der IT Dienstleister der nächsten Generation
          spezialisiert auf Fullstack-Softwareentwicklung für Web, Cloud und
          Mobile. Leichtgewichtig, fair, transparent - zum Kunden und zum
          Mitarbeiter.
        </div>

        <div class="ctaBox-vertical mt-20">
          <div>Herausfordernde Projekte benötigen kompetente Experten.</div>
          <div
            class="flex flex-row justify-center items-center flex-wrap gap-8"
          >
            <router-link
              to="/contact"
              class="btn-hollow rounded-none"
              style="width: 260px"
              >Experten anfragen</router-link
            >
            <router-link
              to="/contact"
              class="btn-hollow rounded-none"
              style="width: 260px"
              >Mitarbeiter werden</router-link
            >
          </div>
        </div>
      </div>

      <div class="sectionDivider"></div>

      <div class="sectionColumn scrollFade">
        <div class="badge">Mission</div>
        <div class="sectionTitle">
          The Software Development Services Company Built On
          <span class="text-webstake-blue">Fairness And Transparency</span>
        </div>
        <div class="sectionText">
          Gute Softwareentwickler sind schwer zu finden und noch schwerer zu
          halten. Wir setzen nach innen wie nach außen auf Transparenz und
          Fairness und folgen den New Work Prinzipien. Das schafft Vertrauen,
          steigert Motivation und Loyalität, und erhöht die Kosteneffizienz.
          Win-win-win für Kunden, Mitarbeiter und Webstake.
        </div>
      </div>
      <div class="cards card-hover mt-8 sectionColumn scrollFade">
        <div class="card">
          <div class="cardIcon bg-webstake-yellow">
            <i class="fa-solid fa-money-bills"></i>
          </div>
          <div class="cardTitle text-left">Faire Entlöhnung</div>
          <div class="cardText text-left">
            Unsere Mitarbeiter werden transparent an Ihrem erzielten Umsatz
            beteiligt. Hochattraktive, marktgerechte Gehälter für die besten IT
            Experten.
          </div>
        </div>
        <div class="card">
          <div class="cardIcon bg-webstake-red">
            <i class="fa-solid fa-house-laptop"></i>
          </div>
          <div class="cardTitle">Remote First</div>
          <div class="cardText">
            Wir arbeiten "remote first" mit unseren Angestellten und Kunden. Das
            ist effizient und vergrößert die Mitarbeiterzufriedenheit und
            -loyalität.
          </div>
        </div>
        <div class="card">
          <div class="cardIcon bg-webstake-blue">
            <i class="fa-regular fa-handshake"></i>
          </div>
          <div class="cardTitle">Zuverlässiger Partner</div>
          <div class="cardText text-left">
            Hochqualifizierte Experten, niedrige Fluktuation, faire Preise. Wir
            bauen mit dem bestmöglichen Service langfristige Kooperationen zu
            unseren Kunden auf.
          </div>
        </div>
        <div class="card">
          <div class="cardIcon bg-webstake-green-dark">
            <i class="fa-solid fa-trophy"></i>
          </div>
          <div class="cardTitle text-left">Bester Arbeitgeber</div>
          <div class="cardText text-left">
            Webstake tritt an mit der Mission, der beste Arbeitgeber für die
            besten Experten zu sein. Modern, leistungsgerecht, effizient,
            fördernd und fordernd.
          </div>
        </div>
      </div>

      <div class="sectionDivider"></div>

      <div class="sectionColumn scrollFade">
        <div class="badge">Services</div>
        <div class="sectionTitle">
          <span class="text-webstake-blue">Agile Softwareentwicklung</span>
          für Web, Cloud und Mobile
        </div>
        <div class="sectionText">
          Deutschland liegt im
          <a
            target="_blank"
            href="https://de.statista.com/statistik/daten/studie/1243006/umfrage/digitalisierungsgrad-der-eu-laender-nach-dem-desi-index/#:~:text=Deutschland%20steht%20beim%20europ%C3%A4ischen%20Vergleich,skandinavischen%20L%C3%A4nder%20Finnland%20und%20D%C3%A4nemark."
            >EU-Ländervergleich des Digitalisierungsgrads von Statista</a
          >
          lediglich auf Rang 13 und hat erheblichen Aufholbedarf. Jedoch gelingt
          es aufgrund des herrschenden Fachkräftemangels vielen Unternehmen
          nicht, die benötigten Ressourcen für sich zu gewinnen. Wir
          unterstützen als Implementierungspartner Deutschlands Großunternehmen
          und Mittelstand bei der Umsetzung Ihrer Digitalprojekte.
        </div>
      </div>
      <div class="sectionColumn scrollFade">
        <div class="ctaBox">
          <div class="flex flex-col">
            <div class="text-left">
              Zuverlässige und kompetente Softwareentwickler zu fairen
              Konditionen?
            </div>
            <div class="text-left">Geht.</div>
          </div>
          <router-link
            to="/contact"
            class="btn-hollow rounded-none"
            style="width: 260px"
            >Jetzt anfragen</router-link
          >
        </div>
      </div>

      <div class="sectionDivider"></div>

      <div class="sectionColumn scrollFade">
        <div class="badge">Standort</div>
        <div class="sectionTitle">
          In Hamburg gegründet,
          <span class="text-webstake-blue">Remote zu Hause</span>
        </div>
        <div class="sectionText">
          Unternehmenssitz der Webstake GmbH ist die schöne Hansestadt Hamburg.
          Jedoch gilt bei uns der Remote-First Ansatz. Als digitaler
          Dienstleister begrüßen wir die Prinzipien des New Work und tun alles,
          um unseren Mitarbeitern und Kunden den Arbeitsalltag so effizient und
          flexibel wie möglich zu gestalten.
        </div>
        <img
          src="@/assets/hamburg-norbert-hentges_unsplash.jpeg"
          alt="Hamburg - Foto by Norbert Hentges on Unsplash"
          class="w-full sm:w-1/3 mb-6 sm:mb-2 sm:mt-3 p-0 rounded-xl"
          style="float: right"
        />
      </div>

      <div class="sectionDivider"></div>

      <div class="sectionColumn scrollFade">
        <div class="badge">Jobs</div>
        <div class="sectionTitle">
          Komm zum
          <span class="text-webstake-blue"
            >Arbeitgeber der nächsten Generation</span
          >
        </div>
        <div class="cards-row">
          <div class="card-transparent">
            <div class="cardIcon-sm bg-webstake-yellow">
              <i class="fa-solid fa-circle-question"></i>
            </div>
            <div class="cardTitle">Motivierter Entwickler?</div>
            <div class="cardText">
              Bist Du ein motivierter Entwickler oder IT-Experte und möchtest
              Dich voll auf Dein technisches Know-how fokussieren? Suchst Du
              einen Weg, Deine gefragten Skills zu marktgerechten Preisen zu
              verkaufen? Bist Du gut in Deinem Job und suchst einen
              Multiplikator, der Dir hilft, Deine Services nutzbringend in
              vielen spannenden Projekten einzubringen?
            </div>
          </div>
          <div class="card-transparent">
            <div class="cardIcon-sm bg-webstake-red">
              <i class="fa-solid fa-binoculars"></i>
            </div>
            <div class="cardTitle">Wir suchen Dich!</div>
            <div class="cardText">
              Dann suchen wir Dich! Denn Webstake bietet IT-lern wie Dir das
              ideale Umfeld für Deine technische Karriere. Du kümmerst Dich um
              Deine Skills, wir kümmern uns um den Rest, wie z.B.
              Projektaqkuise, Buchhaltung, Versicherungen oder Lizenzen.
            </div>
          </div>
          <div class="card-transparent">
            <div class="cardIcon-sm bg-webstake-green-dark">
              <i class="fa-solid fa-people-group"></i>
            </div>
            <div class="cardTitle">Komm zu uns!</div>
            <div class="cardText">
              Unser attraktives Konzept überzeugt durch seine Offenheit immer
              mehr Talente und begeistert sie für unseren Weg. Überzeuge Dich in
              einem unverbindlichen Erstgespräch selbst und lerne unser Modell
              im Detail kennen.
            </div>
          </div>
        </div>
        <div class="sectionText"></div>
        <!-- <router-link
          to="/contact"
          class="btn-hollow rounded-none"
          style="width: 260px"
          >Jetzt Gespräch vereinbaren</router-link
        > -->
      </div>

      <div style="height: 1rem"></div>

      <div class="headline-2 mb-8">
        Deine
        <span class="text-webstake-blue">Vorteile bei Webstake</span>
        im Überblick
      </div>
      <div
        class="cards sectionColumn scrollFade mt-8"
        style="margin-bottom: 6rem"
      >
        <div class="card">
          <div class="cardIcon bg-webstake-yellow" style="border-radius: 50%">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="cardTitle">Attraktive Vergütung</div>
          <div class="cardText">
            Profitiere von einem Gehaltspaket, das seinesgleichen sucht. Mit der
            Sicherheit und Planbarkeit einer Festanstellung, aber dem Potential
            einer Selbständigkeit.
          </div>
        </div>
        <div class="card">
          <div class="cardIcon bg-webstake-red" style="border-radius: 50%">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="cardTitle">Fokus auf IT</div>
          <div class="cardText">
            Konzentriere Dich auf das, was Du am liebsten tust, die
            Softwareentwicklung. Wir vermarkten Deine Skills und schaffen ein
            Umfeld, in dem Du Deine technische Karriere voll entfalten kannst.
          </div>
        </div>
        <div class="card">
          <div
            class="cardIcon bg-webstake-green-dark"
            style="border-radius: 50%"
          >
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="cardTitle">Spannende Projekte</div>
          <div class="cardText">
            Gemeinsam suchen wir immer ein passendes Projekt, das Deine Wünsche
            bezüglich Rolle, Branche, Tech-Stack, Einsatzort etc.
            berücksichtigt. So wird es nie langweilig und Du lernst ständig
            dazu.
          </div>
        </div>
        <div class="card">
          <div class="cardIcon bg-webstake-yellow" style="border-radius: 50%">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="cardTitle">Flexibilität</div>
          <div class="cardText">
            Steuere Deinen Arbeitsalltag und Deine Work-Life-Balance nach
            eigenen Präferenzen. Ob Arbeitsort, -zeiten oder Urlaubstage, wir
            ermöglichen Dir maximale Flexibilität, wenn die Leistung stimmt.
          </div>
        </div>
        <div class="card">
          <div class="cardIcon bg-webstake-red" style="border-radius: 50%">
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="cardTitle">Community</div>
          <div class="cardText">
            Bilde Dich durch internen und externen Wissenaustausch stetig
            weiter. Wir unterstützen uns gegenseitig als Team und haben dabei
            Spaß.
          </div>
        </div>
        <div class="card">
          <div
            class="cardIcon bg-webstake-green-dark"
            style="border-radius: 50%"
          >
            <i class="fa-solid fa-star"></i>
          </div>
          <div class="cardTitle">Partnerschaft</div>
          <div class="cardText">
            Arbeite in einem vertrauensvollen Umfeld. Unser Modell löst die
            Interessenskonflikte zwischen Arbeitgeber und Mitarbeiter auf.
            Webstake profitiert immer nur mit Dir, nie an Dir. Das steigert
            Motivation, Vertrauen und Leistung.
          </div>
        </div>
      </div>
      <div class="sectionColumn scrollFade">
        <div class="ctaBox">
          <div class="flex flex-col">
            <div class="text-left">Bereit für den nächsten Schritt?</div>
            <div class="text-left">
              Vereinbare ein unverbindliches Gespräch, um mehr zu erfahren.
            </div>
          </div>
          <router-link
            to="/contact"
            class="btn-hollow rounded-none"
            style="width: 260px"
            >Jetzt kontaktieren</router-link
          >
        </div>
      </div>
    </div>

    <!-- <div
      class="flex flex-col py-16 items-start"
      style="padding-left: 8.333%; padding-right: 8.333%"
    >
      <div class="paragraph-lg">
        <img
          src="@/assets/undraw_fast_loading.svg"
          alt="Fast loading"
          class="
            w-full
            sm:w-1/3
            ml-12
            mb-6
            sm:mb-2 sm:mt-2
            bg-gray-100
            p-8
            rounded-xl
          "
          style="float: right"
        />
        <span class="font-bold text-webstake-blue">Webstake</span>
        unterstützt ambitionierte Firmen und IT-Teams bei der effektiven
        Umsetzung Ihrer Softwareprojekte. Unsere Consultants bringen fehlende
        Ressourcen, Skills und Erfahrungen ein und helfen dabei, Ihre Projekte
        zu beschleunigen.
        <br /><br />
        Sprechen Sie uns an, wenn Sie:

        <ul class="checkmarks fa-ul">
          <li>
            <span class="fa-li text-webstake-blue"
              ><i class="fa-solid fa-check-square"></i
            ></span>
            Kompetente Softwareentwickler suchen, die schnell, zuverlässig und
            in hoher Qualität Anforderungen umsetzen
          </li>
          <li>
            <span class="fa-li text-webstake-blue"
              ><i class="fa-solid fa-check-square"></i
            ></span>
            Bedarfsspitzen oder Ausfälle zügig und unkompliziert kompensieren
            müssen
          </li>
          <li>
            <span class="fa-li text-webstake-blue"
              ><i class="fa-solid fa-check-square"></i
            ></span>
            Spezielle Expertise und neue Impulse in Ihr Team einbringen wollen
          </li>
        </ul>
      </div>
    </div> -->

    <!-- 
    <div
      class="flex flex-col py-16 items-start"
      style="
        padding-left: 8.333%;
        padding-right: 8.333%;
        background-color: rgb(46, 142, 194, 0.1);
      "
    >
      <div class="paragraph text-left">
       
        <h2 class="text-webstake-blue-darker pb-4">Unsere Leistungen</h2>
        Unsere Consultants bringen Expertise in den folgenden Bereichen mit.

        <span
          class="flex flex-row items-center justify-start gap-2 mb-2 mt-8 pl-2"
        >
          <span class="text-webstake-blue"
            ><i class="fa-solid fa-window-restore"></i
          ></span>
          <span>Frontend-Entwicklung</span>
        </span>
        <span class="flex flex-row flex-wrap gap-2 mb-8 text-sm sm:text-base">
          <span class="chip">JavaScript</span>
          <span class="chip">TypeScript</span>
          <span class="chip">Angular</span>
          <span class="chip">React</span>
          <span class="chip">Vue.js</span>
        </span>

        <span class="flex flex-row items-center gap-2 mb-2 pl-2">
          <span class="text-webstake-blue"
            ><i class="fa-solid fa-server"></i
          ></span>
          Backend-Entwicklung
        </span>
        <span class="flex flex-row flex-wrap gap-2 mb-8 text-sm sm:text-base">
          <span class="chip">Java</span>
          <span class="chip">Kotlin</span>
          <span class="chip">Spring</span>
          <span class="chip">Spring Boot</span>
          <span class="chip">Datenbanken</span>
        </span>

        <span class="flex flex-row items-center gap-2 mb-2 pl-2">
          <span class="text-webstake-blue"
            ><i class="fa-solid fa-ethernet"></i
          ></span>
          Infrastructure & Cloud
        </span>
        <span class="flex flex-row flex-wrap gap-2 mb-8 text-sm sm:text-base">
          <span class="chip">AWS</span>
          <span class="chip">Azure</span>
          <span class="chip">Google Cloud</span>
          <span class="chip">Kubernetes</span>
          <span class="chip">Infrastructure as Code</span>
        </span>

        <span class="flex flex-row items-center gap-2 mb-2 pl-2">
          <span class="text-webstake-blue"
            ><i class="fa-solid fa-gauge-high"></i
          ></span>
          DevOps
        </span>
        <span class="flex flex-row flex-wrap gap-2 mb-8 text-sm sm:text-base">
          <span class="chip">AWS</span>
          <span class="chip">Azure</span>
          <span class="chip">Google Cloud</span>
          <span class="chip">Kubernetes</span>
          <span class="chip">Infrastructure as Code</span>
        </span>

        <span class="flex flex-row items-center gap-2 mb-2 pl-2">
          <span class="text-webstake-blue"
            ><i class="fa-solid fa-mobile-screen-button"></i
          ></span>
          Mobile
        </span>
        <span class="flex flex-row flex-wrap gap-2 text-sm sm:text-base">
          <span class="chip">Android</span>
          <span class="chip">Flutter</span>
        </span>
      </div>
    </div> -->
  </div>

  <!-- <div class="section bg-white text-jet-black">
    <div class="section-content pt-4 pb-8">
      <h1>Webstake - Wenn Anstellung zum Freiberuf wird</h1>

      <h2>Webstake - Dein transparenter und vertrauensvoller Förderer</h2>

      <p class="paragraph">
        Als avantgardistischer Anbieter im Bereich der Softwareentwicklung
        wollen wir den Arbeitsmarkt modernisieren und unseren Kunden durch die
        optimale Versorgung unserer Softwareentwickler das vielversprechendste
        Ergebnis liefern. Um eine faire und zeitgemäße Vergütung bieten zu
        können, arbeiten wir mit einem höchst individuellen System, das den
        Fokus ganz auf den einzelnen Entwickler legt und eine klare Alternative
        zur üblichen sowie starren Entlohnung bietet. Wir verschreiben uns
        dieser Philosophie, weil wir überzeugt sind, dass zufriedene Kunden nur
        mit noch zufriedeneren Entwicklern zu erreichen sind.
      </p>

      <h3>Warum Angestellte zu Freiberuflern werden</h3>

      <p class="paragraph">
        In der Entwicklerbranche stellt sich oftmals die Frage, ob der nächste
        Schritt nach dem Studium oder der Ausbildung in ein Unternehmen oder die
        freiberufliche Tätigkeit führen sollen. Letztere scheint auf den ersten
        Blick viele flexible Vorteile im Gegensatz zu einem
        Anstellungsverhältnis zu haben, bringt aber auch unerwartete
        Schwierigkeiten und bürokratische Hürden mit sich. Wir teilen diese
        Ansicht und bieten unseren Entwicklern ein individuelles Arbeitsumfeld,
        das viele Vorzüge der Freiberuflichkeit bietet und sichern unsere
        Teammitglieder gleichzeitig mit Lohnfortzahlung im Krankheitsfall,
        Festgehalt sowie weiteren Angeboten ab. Damit wollen wir die Zukunft
        dieser Branche prägen und Entwicklern ein selbstbestimmtes Arbeiten
        ermöglichen.
      </p>

      <h3>Fairness und Transparenz</h3>

      <p class="paragraph">
        Die Werte Fairness und Transparenz prägen als Leitmotive unseren Umgang
        im Team von Webstake. Mit dieser Philosophie als Fundament unseres
        Unternehmens haben wir demokratische Strukturen geschaffen, die unsere
        Mitarbeiter direkt am Erfolg des Unternehmens partizipieren sowie den
        eigenen Erfolg individuell steuern lassen. Wir sind der Überzeugung,
        dass harte Gehaltsverhandlungsrunden dem Gestern angehören und haben uns
        aus diesem Grund transparenten sowie fairen und individuellen
        Vergütungssystemen verschrieben.
      </p>

      <h3>Bestimme selbst wieviel du verdienst</h3>

      <p class="paragraph">
        Die Lebensumstände unserer Entwickler sind so individuell wie die
        Wünsche unserer Kunden. Uns kommt es dabei aber nicht nur auf die
        Zufriedenheit unserer Kunden, sondern gleichermaßen auf das Wohlergehen
        unserer Entwickler an. Deshalb bieten wir neben einem festen Gehalt die
        Möglichkeit des individuellen Gehaltsbonus. Jede effektive Arbeitsstunde
        mit der wir dem Kunden mehr helfen können, wirkt sich unmittelbar und
        individuell auf das Jahresgehalt unserer Entwickler aus. Damit wollen
        wir unsere Teammitglieder motivieren und sie aktiv am Erfolg unseres
        Unternehmens partizipieren lassen.
      </p>
    </div>
  </div> -->

  <!-- <salary-calculator></salary-calculator> -->
</template>

<script>
export default {
  components: {},
  name: "Frontpage",
  props: {},
};
</script>

<style scoped>
.titleLine {
  font-size: 24px;
  line-height: 30px;
}
.tagLine {
  color: #666666;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@media only screen and (min-width: 425px) {
  .titleLine {
    font-size: 30px;
    line-height: 39px;
  }
}
@media only screen and (min-width: 640px) {
  .titleLine {
    font-size: 48px;
    line-height: 64px;
  }
  .tagLine {
    color: #6e7191;
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .titleLine {
    font-size: 56px;
    line-height: 74px;
  }
}
@media only screen and (min-width: 1440px) {
  .titleLine {
    font-size: 64px;
    line-height: 85px;
  }
}

.info-box {
  @apply ring-2 ring-blue-300 px-4 py-8 rounded-lg w-1/5;
  min-width: 200px;
}
.service {
  @apply text-xl text-blue-500 cursor-pointer h-48 
  flex justify-center items-center p-4 border
  hover:text-yellow-500 hover:shadow-none hover:bg-gray-200;
}

.logo {
  @apply w-56 mb-12;
}

.benefit {
  @apply flex flex-col border-2 rounded-md mx-6 my-4 w-1/4;
  border-color: var(--webstake-blue);
  color: var(--dark-gray);
  min-width: 16rem;
}
.benefit .header {
  @apply text-center text-white text-left text-xl
            sm:text-2xl
            md:text-2xl p-2;
  background-color: var(--webstake-blue);
}
.benefit .content {
  @apply p-2 text-center text-lg
            sm:text-xl
            md:text-xl;
}

.call-to-action {
  @apply mt-12 px-4 py-2 text-white font-semibold 
  border-2 border-gray-500 rounded-lg text-2xl cursor-pointer;
  background-color: var(--webstake-blue);
  box-shadow: 3px 3px 8px #aaa;
}
.call-to-action:hover {
  background-color: var(--light-blue);
}

.benefit-list-wrapper {
  @apply w-full md:w-1/2  flex flex-col items-stretch justify-items-stretch;
}

.benefit-list {
  @apply flex flex-col items-start justify-center text-lg p-4 pl-8 pt-12 h-full bg-white;
  position: relative;
}
.benefit-list-header {
  @apply text-white p-2 font-bold md:text-xl text-base w-1/2 rounded;
  background-color: var(--webstake-blue);
}
.benefit-list li {
  @apply flex flex-row gap-6 mb-6 items-center text-base sm:text-lg md:text-xl;
  min-height: 3rem;
}
.list-bullet {
  @apply text-4xl;
  color: var(--webstake-blue);
}
.benefit-list li span {
  @apply text-left;
}

.reasons-list {
  @apply mt-6;
}
.reasons-list li {
  @apply flex flex-row gap-2 items-start mb-4 gap-4 text-lg;
}
.reasons-list li svg {
  @apply pt-1;
  color: var(--webstake-blue-darker);
}

.coin {
  @apply bg-white w-10 h-10 rounded-full inline items-center flex justify-center border;
  border-color: var(--webstake-blue);
}
.chip {
  @apply bg-white py-1 px-1 rounded inline items-center flex justify-center border border-gray-200;
}

form {
  @apply flex flex-col justify-start;
}

label {
  @apply text-left pl-1;
}
</style>
