export default {
  "titleLine.1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The software development company built on"])},
  "titleLine.2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fairness"])},
  "titleLine.3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "titleLine.4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transparency"])},
  "frontpage": {
    "tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webstake is your next generation employer for all software developers and IT professionals that want to make the most of their technical career."])},
    "section1": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is why more and more software developers are excited about our concept:"])}
    }
  }
}