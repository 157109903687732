import { createApp } from 'vue'
import App from './App.vue'
import './css/tailwind.css'
import "./css/global.css"
import "./css/ticker.css"
import "./css/cta.css"
import "./css/scrollFade.css"
import "./css/cards.css"
import router from './router'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
import i18n from './i18n'

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

const app = createApp(App).use(i18n)

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router).use(router).mount('#app')


