export default {
  "nav": {
    "concept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konzept"])},
    "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
    "joinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us!"])},
    "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
  },
  "title.leadIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hands-on Software Development Services für Web, Cloud and Mobile"])},
  "titleLine1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullstack Softwareentwickler für Ihre Projekte."])},
  "titleLine2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fair und transparent bezahlt."])},
  "frontpage": {
    "tagline_v1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verstärken Ihre Teams mit hochqualifizierten Entwicklern und beschleunigen die Umsetzung Ihrer Softwareprojekte. Fair, transparent und zuverlässig."])},
    "tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstärken Sie Ihr Team mit Full-Stack Experten für Cloud, Web und Mobile. Beschleunigen Sie Ihre Softwareentwicklung."])},
    "coverStory": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die richtigen Köpfe für Ihr Projektteam"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Digitalisierung von Prozessen und Services ist für die meisten Unternehmen eine überlebenswichtige Aufgabe. Software und die Cloud spielen dabei zentrale Rollen. Die Gewinner von Morgen sind diejenigen, die sich heute den gewachsenen Anforderungen stellen, die Transformation agil angehen und sie mit den richtigen Partnern umsetzen."])},
      "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Implementierungspartner leisten wir für unsere Kunden einen wichtigen Beitrag zur Digitalisierung. Vielen Unternehmen fehlt das qualifizierte Personal, um IT-Projekte zeitnah und effektiv zu realisieren - wir stellen die Experten mit der erforderlichen Erfahrung und Expertise."])}
    },
    "technologies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top technological competences"])}
    },
    "section1": {
      "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darum sind immer mehr Softwareentwickler von unserem Konzept begeistert"])},
      "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webstake steht für Partnerschaft, Transparenz und Fairness. Zentraler Baustein unseres Konzeptes ist die großzügige Beteiligung unserer Mitarbeiter am von Ihnen realisierten Umsatz nach dem Leistungsprinzip. Durch diese Offenheit schaffen wir es, immer mehr Talente für unseren Weg zu begeistern."])},
      "advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorteile bei Webstake:"])},
      "salaryPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Gehaltspaket, das seinesgleichen sucht - durch die transparente Umsatzbeteiligung kommt das Geld beim Leistenden an."])},
      "flexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibilität und Abwechslung - die Arbeit als IT-Consultant bietet beides, wie nur wenige andere Berufsbilder."])},
      "noConflictOfInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Interessenskonflikte zwischen Firma und Mitarbeiter - Webstake profitiert immer nur mit dem Mitarbeiter, nie an ihm. Das steigert Motivation und Vertrauen."])},
      "backup_interestingProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spannende Projekte, die zu Dir passen - Du bist in die Projektauswahl vom ersten Moment an eingebunden und bestimmst die Rahmenbedingungen mit."])},
      "devCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine starke Entwickler-Community - wir konzentrieren uns auf die Softwareentwicklung und verbessern uns durch konsequente Weiterbildung und Wissenaustausch stetig weiter."])},
      "ctaDevelopers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist ein starker Softwareentwickler (m/w/d), der sich voll auf die technische Karriere konzentrieren möchte? Du möchtest Teil eines wachsenden Teams sein, das Dir hilft, Deine Kompetenz und Deinen Marktwert weiterzuentwickeln? Wir suchen laufend nach motivierten Kollegen. Sprich uns an!"])}
    }
  }
}