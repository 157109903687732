<template>
  <div class="content-block items-center" style="background-color: #f8f8f8">
    <div class="sectionColumn mt-8">
      <div class="badge">Expertise</div>
      <div class="sectionTitle">
        <span class="text-webstake-blue">Implementierungspartner</span>
        für Web, Mobile &amp; Cloud
      </div>
      <div class="sectionText">
        Wir sind ein deutschlandweit agierender IT-Dienstleister mit Fokus auf
        die technische Umsetzung geschäftlicher Anwendungen und Prozesse in Web,
        Mobile und Cloud. Wir unterstützen Großunternehmen und Mittelstand als
        Implementierungspartner mit unserem Know-How bei der digitalen
        Transformation.
      </div>
    </div>
    <div class="cards card-hover sectionColumn scrollFade">
      <div class="card">
        <div class="cardIcon bg-webstake-yellow">
          <i class="fa-solid fa-laptop-code"></i>
        </div>
        <div class="cardTitle">Agile Softwareentwicklung</div>
        <div class="cardText">
          Erhöhe die Umsetzungsgeschwindigkeit Deiner digitalen Projekte. Wir
          helfen bei Analyse, Design, Programmierung, Test, Betrieb und Wartung
        </div>
      </div>
      <div class="card">
        <div class="cardIcon bg-webstake-red">
          <i class="fa-solid fa-network-wired"></i>
        </div>
        <div class="cardTitle">DevOps</div>
        <div class="cardText">
          Automatisiere die Delivery- &amp; Release-Prozesse Deiner
          Softwareprojekte durch den Aufbau von end-2-end CI/CD Pipelines.
        </div>
      </div>
      <div class="card">
        <div class="cardIcon bg-webstake-blue">
          <i class="fa-solid fa-cloud-arrow-up"></i>
        </div>
        <div class="cardTitle">Cloud Infrastructure</div>
        <div class="cardText">
          Nutze die Vorteile von modernen, skalierbaren Cloud-Infrastrukturen.
          Wir unterstützen bei Aufbau, Design, Deployment und Migration.
        </div>
      </div>
    </div>

    <div class="sectionDivider"></div>

    <div class="sectionColumn">
      <div class="badge">Zusammenarbeit</div>
      <div class="sectionTitle">
        Kompetente
        <span class="text-webstake-blue">Experten und Teams</span>
        für Deine Projekte
      </div>
      <div class="sectionText">
        Laut Branchenverband bitkom fehlten Ende 2022 in Deutschlands
        Unternehmen rund
        <a
          href="https://www.bitkom.org/Presse/Presseinformation/Deutschland-fehlen-137000-IT-Fachkraefte#:~:text=Derzeit%20fehlen%20in%20Deutschlands%20Unternehmen,2020%20und%202021%20leicht%20abgemildert."
          target="_blank"
          >137.000 IT Fachkräfte</a
        >. Auf allen Kanälen wird händeringend nach qualifiziertem Personal
        gesucht. Unsere kompetenten IT-Experten sind hochmotiviert, Sie in Ihren
        Digitalprojekten zu unterstützen.
      </div>
    </div>
    <div class="cards card-hover sectionColumn scrollFade">
      <div class="card">
        <div class="cardIcon bg-webstake-yellow">
          <i class="fa-solid fa-laptop-code"></i>
        </div>
        <div class="cardTitle">Staffing</div>
        <div class="cardText">
          Finde die richtige Person für Deine spezifischen Anforderungen, bei
          vorübergehenden Personalengpässen oder für eine langfristige
          Zusammenarbeit.
        </div>
      </div>
      <div class="card">
        <div class="cardIcon bg-webstake-red">
          <i class="fa-solid fa-network-wired"></i>
        </div>
        <div class="cardTitle">Cross-funktionale Teams</div>
        <div class="cardText">
          Für größere Bedarfsspitzen stellen wir cross-funktionale Teams in der
          erforderlichen Konstellation und Größe zusammen.
        </div>
      </div>
      <div class="card">
        <div class="cardIcon bg-webstake-blue">
          <i class="fa-solid fa-cloud-arrow-up"></i>
        </div>
        <div class="cardTitle">Integration durch vertragliche Flexibilität</div>
        <div class="cardText">
          Wir bieten sowohl Dienstleistungverträge als auch die
          Arbeitnehmerüberlassung (ANÜ) an, damit unsere Berater bestmöglich in
          Deine Projekte integriert werden können.
        </div>
      </div>
    </div>

    <div class="sectionDivider"></div>

    <div class="sectionColumn scrollFade">
      <div class="badge">Technologien</div>
      <div class="sectionTitle">
        <span class="text-webstake-blue">Fullstack Softwareentwicklung</span>
        im Java-Ökosystem
      </div>
      <div class="sectionText">
        Die Softwareentwicklung entwickelt sich in hohem Tempo. Die folgenden
        Programmiersprachen und Frameworks sind daher nur eine Auswahl der von
        uns eingesetzten Technologien.
      </div>
      <div class="sectionColumn mb-16">
        <div class="cards">
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img src="@/assets/java-logo.png" alt="Java Logo" />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Java</div>
                <div class="cardText">
                  Java nimmt seit ein paar Jahren eine spannende Entwicklung und
                  fügt immer mehr beliebte Features hinzu.
                  <!-- We are excited about the continuous progression of the Java
                  programming language. -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img
                  src="@/assets/spring-logo.svg"
                  style="width: 160px"
                  alt="Spring Logo"
                />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Spring</div>
                <div class="cardText">
                  Erste Wahl für enterprise Anwendungsentwicklung mit Java.
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img
                  src="@/assets/kotlin-logo.png"
                  style="width: 160px"
                  alt="Kotlin Logo"
                />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Kotlin</div>
                <div class="cardText">
                  Bald die erste Wahl unter den JVM-Sprachen? Erlaubt es,
                  kompakten, lesbaren Code zu schreiben.
                  <!-- The up-and-coming first choice in JVM languages? Powerful,
                  precise and readable code. -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img
                  src="@/assets/js-logo.png"
                  style="width: 160px"
                  alt="JavaScript Logo"
                />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">JavaScript</div>
                <div class="cardText">
                  Ein Kind der ersten Stunde des Internets. Und die Basis von
                  client-seitiger Webprogrammierung.
                  <!-- A child of the internet. The foundation of client-side web
                  programming. -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img
                  src="@/assets/ts-logo.png"
                  style="width: 160px"
                  alt="TypeScript Logo"
                />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">TypeScript</div>
                <div class="cardText">
                  JavaScripts großer Bruder. Sehr zu empfehlen für größere
                  Projekte.
                  <!-- JavaScripts grown-up brother. Use to stay sane. -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img src="@/assets/nodejs-logo.png" alt="Node JS Logo" />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Node JS</div>
                <div class="cardText">
                  Server-seitiges JavaScript. Kann man mal machen.
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img src="@/assets/angular-logo.png" alt="Angular Logo" />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Angular</div>
                <div class="cardText">
                  Google's Web-Application Framework für mobile und
                  Desktop-Anwendungen.
                  <!-- Google's web application framework for mobile and desktop. -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img
                  src="@/assets/react-logo.png"
                  style="width: 160px"
                  alt="React Logo"
                />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">React</div>
                <div class="cardText">
                  Facebooks...nein Metas UI Framework. Sehr populär bei vielen
                  Entwicklern.
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img
                  src="@/assets/vue-logo.png"
                  style="width: 160px"
                  alt="VueJS Logo"
                />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">VueJS</div>
                <div class="cardText">
                  Evan Vue's UI Framework. Neuestes der drei wichtigen Web-UI
                  Frameworks und es gewinnt immer mehr Fans.
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront sectionTitle">
                <!-- <img src="@/assets/docker-logo.png" alt="Docker Logo" /> -->
                Docker
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Docker</div>
                <div class="cardText">
                  Natürlich benutzen wir Docker. Wer würde das nicht tun?
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img src="@/assets/kubernetes-logo.png" alt="Kubernetes Logo" />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Kubernetes</div>
                <div class="cardText">Kein Docker ohne Kubernetes.</div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img
                  src="@/assets/aws-logo.png"
                  style="width: 160px"
                  alt="AWS Logo"
                />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">AWS</div>
                <div class="cardText">
                  AWS dominiert den Cloud-Infrastruktur-Markt mit 34%
                  Marktanteil.
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront sectionTitle">
                <!-- <img src="@/assets/azure-logo.png" alt="Azure Logo" /> -->
                Azure
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Azure</div>
                <div class="cardText">
                  Azure ist aktuell der größte Herausforderer für AWS und nimmt
                  immer mehr Fahrt auf.
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img src="@/assets/gcp-logo.png" alt="GCP Logo" />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">GCP</div>
                <div class="cardText">
                  Googles Cloud Platform. Niemals Google unterschätzen.
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront sectionTitle">
                <!-- <img src="@/assets/android-logo.png" alt="Android Logo" /> -->
                Android
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Android</div>
                <div class="cardText">
                  Mobile Entwicklung und Android sind ein und dasselbe.
                </div>
              </div>
            </div>
          </div>
          <div class="card-sm">
            <div class="flipCard-sm">
              <div class="flipCardFront">
                <img src="@/assets/flutter-logo.png" alt="Flutter Logo" />
              </div>
              <div class="flipCardBack">
                <div class="cardTitle">Android</div>
                <div class="cardText">
                  Flutter für moderne UIs auf allen Plattformen.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="ticker-wrapper">
        <div class="ticker">
          <div class="ticker-item">
            <img src="@/assets/java-logo.png" alt="Java Logo" />
          </div>
          <div class="ticker-item">
            <img src="@/assets/kotlin-logo.png" alt="Kotlin Logo" />
          </div>
          <div class="ticker-item">
            <img src="@/assets/js-logo.png" alt="JavaScript Logo" />
          </div>
          <div class="ticker-item">
            <img src="@/assets/ts-logo.png" alt="TypeScript Logo" />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Homepage",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
