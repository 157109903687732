<template>
  <div
    class="
      w-full
      flex
      justify-center
      py-4
      bg-jet-black
      text-white
      border border-black
    "
  >
    <div class="w-5/6 flex flex-row flex-wrap items-start justify-between">
      <div class="flex flex-col items-start my-4 w-48">
        <div class="uppercase text-sm mb-4 font-bold">Anschrift</div>
        <div class="text-sm font-semibold">Webstake GmbH</div>
        <div class="text-sm">Pestalozzistraße 25</div>
        <div class="text-sm">22305 Hamburg</div>
      </div>

      <div class="flex flex-col items-start my-4 w-48">
        <div class="uppercase text-sm mb-4 font-bold">Kontakt</div>
        <div class="text-sm flex flex-row justify-end items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <a href="mailto:kontakt@webstake.de" class="px-2 footer-link">
            kontakt@webstake.de
          </a>
        </div>
        <div class="text-sm flex flex-row justify-end items-center">
          <i class="fa-solid fa-phone" title="Telefonnummer"></i>
          <a class="px-2 footer-link" href="tel:+494052470009">
            +49 (0)40 5247 0009
          </a>
        </div>
      </div>

      <div class="flex flex-col items-start my-4 w-48">
        <div class="uppercase text-sm mb-4 font-bold">Links</div>
        <div>
          <router-link class="footer-link" to="/imprint#top">
            Impressum
          </router-link>
        </div>
        <div>
          <router-link class="footer-link" to="/privacy#top">
            Datenschutz
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div
    class="
      bg-jet-black
      text-white text-center
      w-full
      text-xs
      py-2
      border-t border-white
    "
  >
    &copy; 2023 Webstake GmbH | All rights reserved
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {},
  methods: {},
};
</script>

<style scoped>
.logo-image {
  height: 50px;
}

.footer-link {
  @apply text-base;
  transition: 0.3s;
}
.footer-link:hover {
  color: var(--light-blue);
}

.menu-link.router-link-active {
  @apply border-b-2 border-gray-600 hover:border-blue-600;
}
</style>
