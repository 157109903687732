<template>
  <div id="open-positions" class="section bg-gray-100 text-jet-black">
    <div class="section-content">
      <h1 class="headline">Wanted: die besten Experten!</h1>

      <p class="paragraph" style="padding-bottom: 0">
        Wir suchen laufend gute Leute, die das Selbstvertrauen und den Ehrgeiz
        haben, mit uns durchzustarten. Initiativbewerbungen sind willkommen.
      </p>

      <div
        class="cards card-hover mt-8 sectionColumn scrollFade"
        style="justify-content: flex-start"
      >
        <div class="card">
          <div class="cardIcon bg-webstake-blue">
            <i class="fa-solid fa-terminal"></i>
          </div>
          <div class="cardTitle text-left">
            Senior Fullstack Entwickler (m/w/d)
          </div>
          <div class="cardText text-left">
            <!-- <ul class="enumeration">
              <li>Im Backend Java/Kotlin oder eine andere Jvm-Sprache</li>
              <li>Spring Framework und Spring Boot</li>
              <li>Microservices</li>
              <li>JavaScript und TypeScript im Frontend</li>
              <li>
                Erfahrung mit einem der 3 großen Frontend-Frameworks (Angular,
                React, Vue)
              </li>
              <li>Datenbanken</li>
              <li>Security (z.B. OAuth2, SSO, OWASP Top 10)</li>
              <li>
                CI/CD Pipelines mit Jenkins, Github Actions, Gitlab oder
                ähnlichen Tools
              </li>
              <li>Cloud-Infrastruktur, IaC, DevOps</li>
            </ul> -->
            <b> Anforderungen:</b><br />
            Im Backend Java/Kotlin oder eine andere Jvm-Sprache, Spring Boot,
            Microservices, im Frontend JavaScript/TypeScript und eins der drei
            großen Frameworks (Angular, React, Vue), Datenbanken, Security,
            CI/CD Pipelines Cloud-Infrastruktur... das volle Programm.
            <br />

            <a
              href="http://www.linkedin.com/in/steffentueroff"
              target="_blank"
              title="Send me a message on LinkedIn"
              class="ctaLink mr-4 mt-8"
            >
              <i class="fa-brands fa-linkedin text-2xl"></i>
            </a>
            <a
              href="mailto:info@webstake.de?subject=SeniorFullstackEntwickler"
              target="_blank"
              title="Send an e-mail"
              class="ctaLink"
            >
              <i class="fa-regular fa-envelope text-2xl"></i>
            </a>
          </div>
        </div>

        <div class="card">
          <div class="cardIcon bg-webstake-blue">
            <i class="fa-solid fa-terminal"></i>
          </div>
          <div class="cardTitle text-left">
            Cloud Software Engineer / DevOps (m/w/d)
          </div>
          <div class="cardText text-left">
            <b> Anforderungen:</b><br />
            Mehrere Jahre DevOps-Erfahrung, sehr gute Kenntnisse in Aufbau und
            Konfiguration von Cloud-Infrastrukturen, Infrastructure as Code mit
            z.B. Terraform/CDK, CI/CD Pipelines, Backend Entwicklung
            <br />

            <a
              href="http://www.linkedin.com/in/steffentueroff"
              target="_blank"
              title="Send me a message on LinkedIn"
              class="ctaLink mr-4 mt-8"
            >
              <i class="fa-brands fa-linkedin text-2xl"></i>
            </a>
            <a
              href="mailto:info@webstake.de?subject=CloudSoftwareEngineerDevOps"
              target="_blank"
              title="Send an e-mail"
              class="ctaLink"
            >
              <i class="fa-regular fa-envelope text-2xl"></i>
            </a>
          </div>
        </div>

        <div class="card">
          <div class="cardIcon bg-webstake-blue">
            <i class="fa-solid fa-terminal"></i>
          </div>
          <div class="cardTitle text-left">
            Android / Mobile Entwickler (m/w/d)
          </div>
          <div class="cardText text-left">
            <b> Anforderungen:</b><br />
            Mehrere Jahre Erfahrung im Erstellen von Android-Anwendungen, sehr
            gute Kotlin-Kenntnisse
            <br />

            <a
              href="http://www.linkedin.com/in/steffentueroff"
              target="_blank"
              title="Send me a message on LinkedIn"
              class="ctaLink mr-4 mt-8"
            >
              <i class="fa-brands fa-linkedin text-2xl"></i>
            </a>
            <a
              href="mailto:info@webstake.de?subject=AndroidMobileEntwickler"
              target="_blank"
              title="Send an e-mail"
              class="ctaLink"
            >
              <i class="fa-regular fa-envelope text-2xl"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenPositions",
  props: {},
};
</script>

<style scoped>
</style>
