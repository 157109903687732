<template>
  <div id="about-us" class="section bg-gray-100 text-jet-black">
    <div class="sectionColumn scrollFade" style="width: 80%">
      <div class="badge">Mission</div>
      <div class="sectionTitle" style="max-width: unset">
        Der beste Arbeitgeber für die besten Experten
      </div>
      <div class="sectionText" style="max-width: unset">
        <h2>Angestellter oder Freiberufler?</h2>
        <p class="paragraph">
          Für viele kompetente Fachkräfte, gerade in der Softwareentwicklung,
          ist der Sprung in die Freiberuflichkeit eine attraktive Option.
          Vorausgesetzt man schafft es, das Jahr über mehr oder weniger komplett
          gebucht zu sein, bietet die Freiberuflichkeit hohe Flexibilität,
          Selbstbestimmung und sehr gute Verdienstmöglichkeiten.
        </p>
        <p class="paragraph">
          Doch das Freiberuflerdasein hat auch seine Nachteile. Die
          Ungebundenheit kann dazu führen, dass man sich allein fühlt und als
          Einzelkämpfer emotionslos von Projekt zu Projekt springt. Während
          Angestellte zum Jahresausklang Ihre Weihnachtsfeier genießen, genießt
          man als Externer in der Regel höchstens einen etwas höheren
          Jahresüberschuss. Zudem lenken notwendige Administrationsaufgaben, wie
          z.B. Buchführung, von der eigentlichen Kernkompetenz der
          Beratungstätigkeit ab.
        </p>
        <p class="paragraph">
          Hinzu kommt, daß unser Gesetzgeber den unabhängigen Beratern als
          Ein-Mann-Show reichlich Steine in den Weg legt. Der Nachweis, nicht
          scheinselbständig zu sein, ist eine Hürde, die nur unter größter
          juristischer Unsicherheit überspringbar ist. Viele der Voraussetzungen
          für eine Nicht-Scheinselbständigkeit zielen am Arbeitsalltag in IT
          Projekten vorbei. Für alle beteiligten Parteien bringt das Thema
          Risiken, so daß in der Realität mittlerweile überwiegend alternative
          Vertragsmodelle (wie ANÜ) angewandt werden, wenn Externe zum Einsatz
          kommen.
        </p>

        <h2>Arbeitgeber der nächsten Generation</h2>
        <p class="paragraph">
          <img
            src="@/assets/pic-steffen.jpg"
            alt="Steffen Türoff"
            class="h-80 bg-gray-100 p-8 rounded-full"
            style="float: right"
          />
          Oben genannte Erfahrungen kenne ich, Steffen Türoff, Gründer der
          Webstake GmbH, aus mehr als 15 Jahren Selbständigkeit nur zu gut.
          Hinzu kommt eine gute Portion Frustration aus Bewerbungsverfahren auf
          Festanstellungen. Wieso die gleiche Leistung in einem
          Festanstellungsverhältnis den Unternehmen plötzlich weniger wert ist,
          als bei Unterstützung durch Externe, bleibt mir bis heute ein Rätsel.
        </p>
        <p class="paragraph">
          Wir bei Webstake lösen diesen Missstand auf. Im Vertragsverhältnis mit
          unseren Mitarbeitern kombinieren wir die Vorteile aus den beiden
          Welten Festanstellung und Freiberuflichkeit. Jeder unserer Mitarbeiter
          genießt die Sicherheit eines Festgehalts, Lohnfortzahlung im
          Krankheitsfall und alle weiteren Annehmlichkeiten, die Anstellungen
          attraktiv machen. Und jeder unserer Mitarbeiter ist intern sein
          eigener Profit Center, wird fair und transparent an seinen
          realisierten Umsatzerlösen beteiligt. Arbeitslast, Gehalt,
          Work-Life-Balance, alles wird im Rahmen von Projektparametern für den
          Mitarbeiter selbstbestimmt steuerbar.
        </p>
        <p class="paragraph">
          So sitzen wir bei Webstake mit unseren Kollegen nicht alljährlich mit
          harten Bandagen am Verhandlungstisch oder streiten uns um die
          Verteilung eines Bonustopfs. Wir streben an, unsere Mitarbeiter auf
          die bestmögliche Weise zu unterstützen und weiterzubilden, um aus
          Ihnen die stärksten und wertvollsten Top-Berater zu machen, die sie
          sein können. Nur so profitieren wir als Firma. Unsere Consultants
          wiederum streben das gleiche an. Sie wollen Ihre Arbeitszeit möglichst
          effizient einsetzen. Die Flexibilität, neben einer guten Bezahlung
          Ihren Arbeitsalltag auch nach zusätzlichen Kriterien zu optimieren,
          haben wir bewußt gegeben.
        </p>
      </div>
    </div>

    <div class="headline-2 mb-8">
      Deine
      <span class="text-webstake-blue">Vorteile bei Webstake</span>
      im Überblick
    </div>
    <div
      class="cards sectionColumn scrollFade mt-8"
      style="margin-bottom: 6rem"
    >
      <div class="card">
        <div class="cardIcon bg-webstake-yellow" style="border-radius: 50%">
          <i class="fa-solid fa-star"></i>
        </div>
        <div class="cardTitle">Attraktive Vergütung</div>
        <div class="cardText">
          Profitiere von einem Gehaltspaket, das seinesgleichen sucht. Mit der
          Sicherheit und Planbarkeit einer Festanstellung, aber dem Potential
          einer Selbständigkeit.
        </div>
      </div>
      <div class="card">
        <div class="cardIcon bg-webstake-red" style="border-radius: 50%">
          <i class="fa-solid fa-star"></i>
        </div>
        <div class="cardTitle">Fokus auf IT</div>
        <div class="cardText">
          Konzentriere Dich auf das, was Du am liebsten tust, die
          Softwareentwicklung. Wir vermarkten Deine Skills und schaffen ein
          Umfeld, in dem Du Deine technische Karriere voll entfalten kannst.
        </div>
      </div>
      <div class="card">
        <div class="cardIcon bg-webstake-green-dark" style="border-radius: 50%">
          <i class="fa-solid fa-star"></i>
        </div>
        <div class="cardTitle">Spannende Projekte</div>
        <div class="cardText">
          Gemeinsam suchen wir immer ein passendes Projekt, das Deine Wünsche
          bezüglich Rolle, Branche, Tech-Stack, Einsatzort etc. berücksichtigt.
          So wird es nie langweilig und Du lernst ständig dazu.
        </div>
      </div>
      <div class="card">
        <div class="cardIcon bg-webstake-yellow" style="border-radius: 50%">
          <i class="fa-solid fa-star"></i>
        </div>
        <div class="cardTitle">Flexibilität</div>
        <div class="cardText">
          Steuere Deinen Arbeitsalltag und Deine Work-Life-Balance nach eigenen
          Präferenzen. Ob Arbeitsort, -zeiten oder Urlaubstage, wir ermöglichen
          Dir maximale Flexibilität, wenn die Leistung stimmt.
        </div>
      </div>
      <div class="card">
        <div class="cardIcon bg-webstake-red" style="border-radius: 50%">
          <i class="fa-solid fa-star"></i>
        </div>
        <div class="cardTitle">Community</div>
        <div class="cardText">
          Bilde Dich durch internen und externen Wissenaustausch stetig weiter.
          Wir unterstützen uns gegenseitig als Team und haben dabei Spaß.
        </div>
      </div>
      <div class="card">
        <div class="cardIcon bg-webstake-green-dark" style="border-radius: 50%">
          <i class="fa-solid fa-star"></i>
        </div>
        <div class="cardTitle">Partnerschaft</div>
        <div class="cardText">
          Arbeite in einem vertrauensvollen Umfeld. Unser Modell löst die
          Interessenskonflikte zwischen Arbeitgeber und Mitarbeiter auf.
          Webstake profitiert immer nur mit Dir, nie an Dir. Das steigert
          Motivation, Vertrauen und Leistung.
        </div>
      </div>
    </div>

    <div class="sectionColumn scrollFade">
      <div class="ctaBox">
        <div class="flex flex-col">
          <div class="text-left">Bereit für den nächsten Schritt?</div>
          <div class="text-left">
            Vereinbare ein unverbindliches Gespräch, um mehr zu erfahren.
          </div>
        </div>
        <router-link
          to="/contact"
          class="btn-hollow rounded-none"
          style="width: 260px"
          >Jetzt kontaktieren</router-link
        >
      </div>
    </div>

    <!-- <div class="section-content">
      <h1 class="text-webstake-blue">Wenn Anstellung zum Freiberuf wird</h1>

      <h2>Webstake - Dein transparenter und vertrauensvoller Förderer</h2>

      <p class="paragraph">
        Als avantgardistischer Anbieter im Bereich der Softwareentwicklung
        wollen wir den Arbeitsmarkt modernisieren und unseren Kunden durch die
        optimale Versorgung unserer Softwareentwickler das vielversprechendste
        Ergebnis liefern. Um eine faire und zeitgemäße Vergütung bieten zu
        können, arbeiten wir mit einem höchst individuellen System, das den
        Fokus ganz auf den einzelnen Entwickler legt und eine klare Alternative
        zur üblichen sowie starren Entlohnung bietet. Wir verschreiben uns
        dieser Philosophie, weil wir überzeugt sind, dass zufriedene Kunden nur
        mit noch zufriedeneren Entwicklern zu erreichen sind.
      </p>

      <h3>Warum Angestellte zu Freiberuflern werden</h3>

      <p class="paragraph">
        In der Entwicklerbranche stellt sich oftmals die Frage, ob der nächste
        Schritt nach dem Studium oder der Ausbildung in ein Unternehmen oder die
        freiberufliche Tätigkeit führen sollen. Letztere scheint auf den ersten
        Blick viele flexible Vorteile im Gegensatz zu einem
        Anstellungsverhältnis zu haben, bringt aber auch unerwartete
        Schwierigkeiten und bürokratische Hürden mit sich. Wir teilen diese
        Ansicht und bieten unseren Entwicklern ein individuelles Arbeitsumfeld,
        das viele Vorzüge der Freiberuflichkeit bietet und sichern unsere
        Teammitglieder gleichzeitig mit Lohnfortzahlung im Krankheitsfall,
        Festgehalt sowie weiteren Angeboten ab. Damit wollen wir die Zukunft
        dieser Branche prägen und Entwicklern ein selbstbestimmtes Arbeiten
        ermöglichen.
      </p>

      <h3>Fairness und Transparenz</h3>

      <p class="paragraph">
        Die Werte Fairness und Transparenz prägen als Leitmotive unseren Umgang
        im Team von Webstake. Mit dieser Philosophie als Fundament unseres
        Unternehmens haben wir demokratische Strukturen geschaffen, die unsere
        Mitarbeiter direkt am Erfolg des Unternehmens partizipieren sowie den
        eigenen Erfolg individuell steuern lassen. Wir sind der Überzeugung,
        dass harte Gehaltsverhandlungsrunden dem Gestern angehören und haben uns
        aus diesem Grund transparenten sowie fairen und individuellen
        Vergütungssystemen verschrieben.
      </p>

      <h3>Bestimme selbst, wieviel du verdienst</h3>

      <p class="paragraph">
        Die Lebensumstände unserer Entwickler sind so individuell wie die
        Wünsche unserer Kunden. Uns kommt es dabei aber nicht nur auf die
        Zufriedenheit unserer Kunden, sondern gleichermaßen auf das Wohlergehen
        unserer Entwickler an. Deshalb bieten wir neben einem festen Gehalt die
        Möglichkeit des individuellen Gehaltsbonus. Jede effektive Arbeitsstunde
        mit der wir dem Kunden mehr helfen können, wirkt sich unmittelbar und
        individuell auf das Jahresgehalt unserer Entwickler aus. Damit wollen
        wir unsere Teammitglieder motivieren und sie aktiv am Erfolg unseres
        Unternehmens partizipieren lassen.
      </p> -->

    <!-- <h2 class="headline-2 text-dark-gray">
        Motivation - Angestellter oder Freiberufler?
      </h2>
      <div class="paragraph">
        Für viele kompetente Fachkräfte, gerade in der Softwareentwicklung, ist
        der Sprung in die Freiberuflichkeit eine attraktive Option.
        Vorausgesetzt man schafft es, das Jahr über mehr oder weniger komplett
        gebucht zu sein, bietet die Freiberuflichkeit hohe Flexibilität,
        Selbstbestimmung und sehr gute Verdienstmöglichkeiten.
      </div>
      <div class="paragraph">
        Doch das Freiberuflerdasein hat auch seine Nachteile. Die Ungebundenheit
        kann dazu führen, dass man sich allein fühlt und als Einzelkämpfer
        emotionslos von Projekt zu Projekt springt. Während Angestellte zum
        Jahresausklang Ihre Weihnachtsfeier genießen, genießt man als Externer
        in der Regel höchstens einen etwas höheren Jahresüberschuss. Zudem
        lenken notwendige Administrationsaufgaben, wie z.B. Buchführung, von der
        eigentlichen Kernkompetenz der Beratungstätigkeit ab.
      </div>
      <div class="paragraph">
        Hinzu kommt, daß unser Gesetzgeber den unabhängigen Beratern als
        Ein-Mann-Show reichlich Steine in den Weg legt. Der Nachweis, nicht
        scheinselbständig zu sein, ist eine Hürde, die nur unter größter
        juristischer Unsicherheit überspringbar ist. Viele der Voraussetzungen
        für eine Nicht-Scheinselbständigkeit zielen am Arbeitsalltag in IT
        Projekten vorbei. Für alle beteiligten Parteien bringt das Thema
        Risiken, so daß in der Realität mittlerweile überwiegend alternative
        Vertragsmodelle (wie ANÜ) angewandt werden, wenn Externe zum Einsatz
        kommen.
      </div> -->

    <!-- <div class="headline-2 text-dark-gray">
        Vision - Der beste Arbeitgeber für die besten Experten
      </div>
      <div class="paragraph">
        Oben genannte Erfahrungen kenne ich, Steffen Türoff, Gründer der
        Webstake GmbH, aus mehr als 15 Jahren Selbständigkeit nur zu gut. Hinzu
        kommt eine gute Portion Frustration aus Bewerbungsverfahren auf
        Festanstellungen. Wieso die gleiche Leistung in einem
        Festanstellungsverhältnis den Unternehmen plötzlich weniger wert ist,
        als bei Unterstützung durch Externe, bleibt mir bis heute ein Rätsel.
      </div>
      <div class="paragraph">
        Wir bei Webstake lösen diesen Missstand auf. Im Vertragsverhältnis mit
        unseren Mitarbeitern kombinieren wir die Vorteile aus den beiden Welten
        Festanstellung und Freiberuflichkeit. Jeder unserer Mitarbeiter genießt
        die Sicherheit eines Festgehalts, Lohnfortzahlung im Krankheitsfall und
        alle weiteren Annehmlichkeiten, die Anstellungen attraktiv machen. Und
        jeder unserer Mitarbeiter ist intern sein eigener Profit Center, wird
        fair und transparent an seinen realisierten Umsatzerlösen beteiligt.
        Arbeitslast, Gehalt, Work-Life-Balance, alles wird im Rahmen von
        Projektparametern für den Mitarbeiter selbstbestimmt steuerbar.
      </div>
      <div class="paragraph">
        So sitzen wir bei Webstake mit unseren Kollegen nicht alljährlich mit
        harten Bandagen am Verhandlungstisch oder streiten uns um die Verteilung
        eines Bonustopfs. Wir streben an, unsere Mitarbeiter auf die
        bestmögliche Weise zu unterstützen und weiterzubilden, um aus Ihnen die
        stärksten und wertvollsten Top-Berater zu machen, die sie sein können.
        Nur so profitieren wir als Firma. Unsere Consultants wiederum streben
        das gleiche an. Sie wollen Ihre Arbeitszeit möglichst effizient
        einsetzen. Die Flexibilität, neben einer guten Bezahlung Ihren
        Arbeitsalltag auch nach zusätzlichen Kriterien zu optimieren, haben wir
        bewußt gegeben.
      </div> -->
    <!-- </div> -->
    <!-- <salary-calculator /> -->
  </div>
</template>

<script>
// import SalaryCalculator from "../components/SalaryCalculator.vue";

export default {
  components: {
    // SalaryCalculator
  },
  name: "AboutUs",
  props: {},
};
</script>

<style scoped>
</style>
